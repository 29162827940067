// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-benefits-dead-sea-salt-js": () => import("./../../../src/pages/benefits/dead-sea-salt.js" /* webpackChunkName: "component---src-pages-benefits-dead-sea-salt-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-benefits-mineral-chlorinator-js": () => import("./../../../src/pages/benefits/mineral-chlorinator.js" /* webpackChunkName: "component---src-pages-benefits-mineral-chlorinator-js" */),
  "component---src-pages-benefits-ozone-pool-purification-js": () => import("./../../../src/pages/benefits/ozone-pool-purification.js" /* webpackChunkName: "component---src-pages-benefits-ozone-pool-purification-js" */),
  "component---src-pages-benefits-pool-health-js": () => import("./../../../src/pages/benefits/pool-health.js" /* webpackChunkName: "component---src-pages-benefits-pool-health-js" */),
  "component---src-pages-benefits-pool-ph-js": () => import("./../../../src/pages/benefits/pool-ph.js" /* webpackChunkName: "component---src-pages-benefits-pool-ph-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-book-a-consultation-js": () => import("./../../../src/pages/book-a-consultation.js" /* webpackChunkName: "component---src-pages-book-a-consultation-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-s-js": () => import("./../../../src/pages/s.js" /* webpackChunkName: "component---src-pages-s-js" */),
  "component---src-pages-the-science-js": () => import("./../../../src/pages/the-science.js" /* webpackChunkName: "component---src-pages-the-science-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

